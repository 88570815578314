<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
    />
  </div>
</template>

<script>

import {dealCompanyJoinApply} from "@/api";

export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const cmd = this.$store.state.cmd
    return {
      cmd,
      formData: {},
      config: [
        // lang:分配部门
        {
          tag: 'el-select',
          label: this.$t('companyApplyJoinAccountListManage.assignGroup'),
          prop: 'groupUid',
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
          },
        },
      ],
    }
  },
  mounted() {
    this.userAccountGroupFindTree()
  },
  methods: {
    // 查询终端功能
    userAccountGroupFindTree() {
      this.$ajax({
        url: this.$api.userAccountGroupFindTree,
        data: {
          companyId: this.$store.getters.company.id,
          scope: 'COMPANY',
        }
      }).then((data) => {
        const list = data || []
        this.config[0].tagProps.options = list.map((item) => ({
          label: item.name,
          value: item.uid,
        }))
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dealCompanyJoinApply,
        data: {
          isAgree: 'YES',
          groupUid: formData.groupUid,
          applyRecordId: this.tableRow.id,
        }
      }).then(res => {
        this.$emit('closePage', 'confirm')
      })
    },
  },
}
</script>
